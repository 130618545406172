<template>
    <div>
        <Horizontal v-if="$store.getters.currentPageVersion === 'Horizontal'"
        :giftDetail="giftDetail" @copyCdk="copyCdk" @getGift="getGift"></Horizontal>
        <Vertical v-else
        :giftDetail="giftDetail" @copyCdk="copyCdk" @getGift="getGift"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Welfare/GiftDetail/Horizontal/Index.vue'
    import Vertical from '@/components/Welfare/GiftDetail/Vertical/Index.vue'
    

    export default {
        components:{
            Horizontal,
            Vertical
        },
        data() {
            return {
               giftDetail:{}
            }
        },
        created() {
            this.getGiftDetail()
        },
        methods:{
            //复制礼包码
            copyCdk() {
                let requestData = {
                		user_id: this.$store.state.userId,
                		token: this.$store.state.token,
                		gift_cat_id: this.giftDetail.gift_cat_id,
                		gift_id:this.giftDetail.gift_id
                	}
                this.$api.game.getGiftPack(requestData).then(res=> {
                	if (res.data.code === 200) {
                        this.$common.copyText(res.data.data.card_number, '礼包码')
                	}
                })
                
            },
            getGift() {
                let requestData = {
                		user_id: this.$store.state.userId,
                		token: this.$store.state.token,
                		gift_cat_id: this.giftDetail.gift_cat_id,
                		gift_id:this.giftDetail.gift_id
                	}
                this.$api.game.getGiftPack(requestData).then(res=> {
                	if (res.data.code === 200) {
                        this.$Toast({
                        	message: '领取成功！',
                        })
                        setTimeout(()=> {
                        	this.getGiftDetail()
                        }, 2000)
                	}
                })
            },
           getGiftDetail() {
               let requestData = {
                   user_id:this.$store.state.userId,
                   token:this.$store.state.token,
                   gift_cat_id:this.$route.params.item.gift_cat_id,
				   gift_id:this.$route.params.item.gift_id,
               }
               this.$api.game.getGiftDetail(requestData).then(res=> {
                   if (res.data.code === 200) {
                       this.giftDetail = res.data.data
                   }
               })
               
           }
        }
    }
</script>

<style scoped>
  
</style>