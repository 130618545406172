<template>
    <div class="all-page">
        <TopNav title="礼包详情" backPath="WelfareIndex"></TopNav>
        <div class="all-page-content-box">
            <div class="use-explain">
                <div class="use-explain-title">
                    使用说明
                </div>
                <div class="use-explain-content">
                    {{giftDetail.how_to_use}}
                </div>
            </div>
            <div class="gift-info-box">
                <div class="gift-item-box">
                    <div class="gift-info-title">
                        <div></div>礼包名称
                    </div>
                    <div class="gift-info-content">
                        {{giftDetail.gift_name}}
                    </div>
                </div>
                <div class="gift-item-box">
                    <div class="gift-info-title">
                        <div></div>礼包详情
                    </div>
                    <div class="gift-info-content">
                        {{giftDetail.content}}
                    </div>
                </div>
                <div class="gift-item-box">
                    <div class="gift-info-title">
                        <div></div>有效期
                    </div>
                    <div class="gift-info-content">
                        {{giftDetail.get_start_time | changDayType}}-
                        {{giftDetail.get_end_time | changTimeType}}
                    </div>
                </div>
            </div>
            <div class="get-btn-box">
                <div class="get-btn" @click="getGift" v-if="giftDetail.is_receive === 0">
                    领取
                </div>
                <div class="copy-btn" v-else @click="copyCdk">
                    复制
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"
    export default {
        components: {
            TopNav
        },
        props: {
            giftDetail: Object
        },
        data() {
            return {

            }
        },
        methods: {
            copyCdk() {
                console.log("viugduivhsbv")
                this.$emit('copyCdk')
            },
            getGift() {
                this.$emit('getGift')
            }
        }
    }
</script>

<style scoped>
    .use-explain-title {
        font-size: 15px;
        font-weight: 500;
    }
    .use-explain-content {
        padding: 7px 0 15px 0;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
    }
   .gift-item-box {
       padding-bottom: 12px;
   }
   .gift-info-title {
       font-size: 15px;
       font-weight: 400;
       color: #000000;
       display: flex;
       align-items: center;
       justify-content: flex-start;
   }
   .gift-info-title>div {
       width: 4px;
       height: 4px;
       border-radius: 50%;
       background-color: #16C5CE;
       margin-right: 3px;
   }
   .gift-info-content {
       font-size: 14px;
       font-weight: 400;
       line-height: 20px;
       color: #666666;
       display: flex;
       align-items: center;
       justify-content: flex-start;
   }
   .get-btn-box {
       padding-top: 78px;
   }
   .copy-btn,
   .get-btn {
       width: 100%;
       height: 40px;
       border-radius: 6px;
       font-size: 16px;
       font-weight: 400;
       color: #FFFFFF;
       display: flex;
       align-items: center;
       justify-content: center;
   }
   .copy-btn {
       border: 1px solid #00C9D0;
       color: #00C9D0;
   }
   .get-btn {
       background-color: #16C5CE;
   }
 </style>
